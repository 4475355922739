import React, { useEffect } from 'react';
import { animate, motion } from "framer-motion";
import mixpanel from 'mixpanel-browser';

mixpanel.init('928068c30b134aac2b92eaac45c5bb87', {
    track_pageview: true
});

const container = {
    animate: {
      transition: {
        type: "ease",
        staggerChildren: 0.05
      }
    }
  }
  
const item = {
    initial: { x: -8, opacity: 0 },
    animate: { x: 0, opacity: 1 }
}
  
const itemRight = {
    initial: { x: 8, opacity: 0 },
    animate: { x: 0, opacity: 1 },
    hover: { 
        x: 2,
        transition: {
            type: "ease",
            duration: 0.2
        } 
    }
}

const arrowButton = {
    hover: { 
        rotate: 45,
        transition: {
            type: "ease",
            duration: 0.05
        } 
    }
}

const linkButton = {
    initial: { y: -8, opacity: 0.75 },
    animate: { y: 0, opacity: 1 }
}


function LeftSidebarMain() {
    useEffect(() => {
        mixpanel.track_links(".button--linkedin", "Linkedin_button_clicked");
        mixpanel.track_links(".button--telegram", "Telegram_button_clicked");
        mixpanel.track_links(".button--email", "Email_button_clicked");
        mixpanel.track_links(".button--readcv", "Readcv_button_clicked");
    }, []);
  return (
    <div className="left--sidebar--main">
        <div className="left-sidebar--personal">
            <div className="personal--heading">
                <motion.div
                    initial={{ x: -8 }}
                    animate={{ x: 0 }}
                    className="personal--profile-picture"
                >
                    <img src="assets/static/personal-photo/profile_picture.png" alt="profile_picture" />
                </motion.div>
                <motion.div
                    variants={container}
                    initial="initial"
                    animate="animate"
                    className="personal--subtitles"
                >
                    <motion.h1
                        variants={item}
                    >
                        Ruslan Maslenkou
                    </motion.h1>
                    <motion.h2
                        variants={item}
                    >
                        Product / Founding Designer
                    </motion.h2>
                </motion.div>
            </div>

            <motion.div
                initial={{ y: -8 }}
                animate={{ y: 0 }}
                className="personal--summary"
            >
                <p>Pragmatic product designer with 6+ years of experience designing information-intensive interfaces for web and mobile applications in development tools, AI, and crypto products.</p>
            </motion.div>

            <motion.div
                variants={container}
                initial="initial"
                animate="animate"
                className="personal--contact-buttons"
            >
                <motion.a 
                    variants={linkButton}
                    whileHover={{ scale: 1.01 }}
                    href="https://linkedin.com/in/maslenkou/" 
                    target="_blank" 
                    rel="noreferrer"  
                    className="button--linkedin"
                >
                    <img src="assets/static/icons/linkedin--white.svg" alt="linkedin_icon" />
                    LinkedIn
                </motion.a>
                <motion.a
                    variants={linkButton}
                    whileHover={{ scale: 1.01 }}
                    href="https://t.me/maslenkou" 
                    target="_blank" 
                    rel="noreferrer" 
                    className="button--telegram"
                >
                    <img src="assets/static/icons/telegram--white.svg" alt="telegram_icon" />
                    Telegram
                </motion.a>
                <motion.a
                    variants={linkButton}
                    whileHover={{ scale: 1.01 }}
                    href="mailto:ruslanmaslenkou@gmail.com" 
                    className="button--email"
                >
                    <img src="assets/static/icons/mail--white.svg" alt="email_icon" />
                    Email
                </motion.a>
            </motion.div>

            <div className="personal--latest-expirience">

                <motion.div 
                    className="latest-expirience--heading"
                >
                    <motion.h4
                        initial="initial"
                        animate="animate"
                        variants={item}
                    >
                        Latest experience
                    </motion.h4>
                    <div className="latest-expirience--read-more">
                        <motion.a 
                            initial="initial"
                            animate="animate"
                            whileHover="hover"
                            variants={itemRight}
                            className="button--readcv"
                            href="https://read.cv/maslenkou" target="_blank" rel="noreferrer"
                        >
                            Read more
                            <motion.img 
                                variants={arrowButton} 
                                src="assets/static/icons/arrow_forward--black.svg" 
                                alt="arrow_forward" 
                            />
                        </motion.a>
                    </div>
                </motion.div>

                <motion.div 
                    variants={container}
                    initial="initial"
                    animate="animate"
                    className="latest-expirience--body"
                >

                    <div className="latest-expirience--item">
                        <motion.div 
                            variants={item}
                            className="latest-expirience--item--content-left"
                        >
                            <img src="assets/static/company-logos/appvelocity.svg" alt="" className="latest-expirience--item--logo" />
                            <div className="latest-expirience--item--title">
                                <h5>Senior Product Designer</h5>
                                <h6>AppVelocity</h6>
                            </div>
                        </motion.div>
                        <motion.div 
                            variants={itemRight}
                            className="latest-expirience--item--content-right"
                        >
                            <p>2023 – Now</p>
                        </motion.div>
                    </div>

                    <div className="latest-expirience--item">
                        <motion.div 
                            variants={item}
                            className="latest-expirience--item--content-left"
                        >
                            <img src="assets/static/company-logos/jsight.svg" alt="" className="latest-expirience--item--logo" />
                            <div className="latest-expirience--item--title">
                                <h5>Senior Product Designer</h5>
                                <h6>JSight</h6>
                            </div>
                        </motion.div>
                        <motion.div 
                            variants={itemRight}
                            className="latest-expirience--item--content-right"
                        >
                            <p>2021 – 2022</p>
                        </motion.div>
                    </div>

                    <div className="latest-expirience--item">
                        <motion.div 
                            variants={item}
                            className="latest-expirience--item--content-left"
                        >
                            <img src="assets/static/company-logos/teachmeskills.svg" alt="" className="latest-expirience--item--logo" />
                            <div className="latest-expirience--item--title">
                                <h5>UX/UI Course Mentor</h5>
                                <h6>TeachMeSkills</h6>
                            </div>
                        </motion.div>
                        <motion.div 
                            variants={itemRight}
                            className="latest-expirience--item--content-right"
                        >
                            <p>2021</p>
                        </motion.div>
                    </div>

                    <div className="latest-expirience--item">
                        <motion.div 
                            variants={item}
                            className="latest-expirience--item--content-left"
                        >
                            <img src="assets/static/company-logos/gradient.svg" alt="" className="latest-expirience--item--logo" />
                            <div className="latest-expirience--item--title">
                                <h5>Middle Product Designer</h5>
                                <h6>Gradient App</h6>
                            </div>
                        </motion.div>
                        <motion.div 
                            variants={itemRight}
                            className="latest-expirience--item--content-right"
                        >
                            <p>2020 – 2021</p>
                        </motion.div>
                    </div>
                </motion.div>
            </div>
        </div>
        <motion.div 
            initial="initial"
            animate="animate"
            variants={item}
            className="left-sidebar--location"
        >
            <span>Born and raised in</span>
            <img src="assets/static/flags/belarus.svg" alt="belarus-flag" />
            <span>Live in</span>
            <img src="assets/static/flags/poland.svg" alt="poland-flag" />
        </motion.div>
    </div>
  );
}

export default LeftSidebarMain;